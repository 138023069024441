import React from 'react';
import { Input } from 'antd';
import { Controller } from 'react-hook-form';

interface InputItemProps {
  name: string;
  disabled?: boolean;
  size?: 'small' | 'default' | 'large';
  initialValue?: string;
  control: any;
  inputType?: string;
  placeholder?: string;
  min?: number;
  allowClear?: boolean;
  maxLength?: number;
  onKeyPress?: () => void;
}

const onWheel = (event?: React.WheelEvent<HTMLInputElement>): void => {
  (event?.target as HTMLElement)?.blur();
};

const InputItem = ({ name, disabled, size, initialValue, control, inputType, placeholder, min, maxLength, onKeyPress }: InputItemProps) => (
  <Controller
    name={name}
    data-testid={`input-${name}`}
    as={
      <Input
        id={name}
        type={inputType || 'text'}
        name={name}
        disabled={disabled}
        size={size}
        defaultValue={initialValue}
        placeholder={placeholder}
        maxLength={maxLength}
        {...(inputType === 'number' && { min, onKeyPress, onWheel })}
      />
    }
    control={control}
    defaultValue={initialValue}
  />
);

export default InputItem;
